import React from 'react';
import Intro from '../components/Intro/Intro';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import './patient-stories.scss';
import VideoPlayer from '../components/VideoPlayer/VideoPlayer';

const PatientStories = ({ data }) => {
  function getImageForStory(storyImage) {
    const imageData = data.patientStoriesImages.nodes.find(
      ({ name }) => name.toLowerCase() === storyImage.toLowerCase()
    );
    return imageData.childImageSharp.gatsbyImageData;
  }

  return (
    <Layout>
      <Intro
        heading={data.introText.childTextContentJson.intro.heading}
        description={data.introText.childTextContentJson.intro.description}
        // imageData={data.introImage.childImageSharp.gatsbyImageData}
        // button={<Button label="Nominate a Patient" type="button" />}
      />

      <section className="patient-story-videos">
        <VideoPlayer
          heading={data.videosText.childTextContentJson.videos.bigVideo.heading}
          description={
            data.videosText.childTextContentJson.videos.bigVideo.description
          }
          imageData={data.topStoryImage.childImageSharp.gatsbyImageData}
          videoLink={
            data.videosText.childTextContentJson.videos.bigVideo.videoLink
          }
        />
        <div className="patient-story-videos__small-videos">
          {data.videosText.childTextContentJson.videos.smallVideos.map(
            (story, index) => (
              <VideoPlayer
                key={story.videoLink}
                heading={story.heading}
                description={story.description}
                size="SMALL"
                imageData={getImageForStory(story.image)}
                videoLink={story.videoLink}
              />
            )
          )}
        </div>
      </section>
    </Layout>
  );
};

export default PatientStories;

export const query = graphql`
  query PatientStoriesQuery {
    introText: file(relativePath: { eq: "patient-stories.json" }) {
      childTextContentJson {
        intro {
          heading
          description
        }
      }
    }
    introImage: file(relativePath: { eq: "patient-stories.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    videosText: file(relativePath: { eq: "patient-stories.json" }) {
      childTextContentJson {
        videos {
          bigVideo {
            heading
            description
            videoLink
          }
          smallVideos {
            heading
            description
            videoLink
            image
          }
        }
      }
    }
    topStoryImage: file(relativePath: { eq: "patient-stories/top-story.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    patientStoriesImages: allFile(
      filter: { relativeDirectory: { eq: "patient-stories" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;

import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import sprite from '../../../static/sprite.svg';
import './VideoPlayer.scss';

const VideoPlayer = ({
  heading,
  description,
  size = 'BIG',
  imageData,
  videoLink,
}) => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div
      className={`video-player ${
        size === 'SMALL' ? 'video-player--small' : ''
      }`}
    >
      <div
        className={`video-player__background-image-wrapper ${
          size === 'SMALL'
            ? 'video-player__background-image-wrapper--small'
            : ''
        }`}
      >
        <div
          className={`video-player__background-image-box ${
            size === 'SMALL' ? 'video-player__background-image-box--small' : ''
          }`}
        >
          <GatsbyImage
            image={imageData}
            alt="background image"
            className={`video-player__background-image ${
              size === 'SMALL' ? 'video-player__background-image--small' : ''
            }`}
          />
        </div>
      </div>
      <div className="video-player__separator" />
      <div
        className={`video-player__content ${
          size === 'SMALL' ? 'video-player__content--small' : ''
        }`}
      >
        <span
          className={`video-player__icon-box ${
            size === 'SMALL' ? 'video-player__icon-box--small' : ''
          } `}
          onClick={() => setShowPopup(true)}
        >
          <svg className="video-player__icon">
            <use href={`${sprite}#play`}></use>
          </svg>
        </span>
        <h3 className="video-player__heading heading-tertiary">{heading}</h3>
        <p
          className={`video-player__description ${
            size === 'SMALL' ? 'video-player__description--small' : ''
          }`}
        >
          {description}
        </p>
      </div>

      {showPopup && (
        <>
          <div className="video-player__backdrop" />
          <div className="video-player__popup">
            <button
              type="button"
              className="video-player__close-btn"
              onClick={() => setShowPopup(false)}
            ></button>
            <iframe
              src={videoLink}
              frameborder="0"
              className="video-player__iframe"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            {/* <div
              className="video-player__iframe"
              dangerouslySetInnerHTML={{ __html: videoLink }}
            /> */}
          </div>
        </>
      )}
    </div>
  );
};

export default VideoPlayer;
